import { useEffect } from 'react';

interface SSEOptions {
  enabled?: boolean;
  onMessage?: (data: any) => void;
  onError?: (error: any) => void;
}

export const useSSE = ({ enabled, onMessage, onError }: SSEOptions) => {
  useEffect(() => {
    if (!enabled) return;

    const token = localStorage.getItem('sessionToken');
    if (!token) return;

    const trimmedToken = token.slice(1, -1);
    const sse = new EventSource(
      `${process.env.REACT_APP_API_BASE_URL}/store/sse?t=${trimmedToken}`
    );

    sse.onmessage = (event) => {
      try {
        onMessage?.(event.data);
      } catch (error) {
        console.error('Error parsing SSE message:', error);
      }
    };

    sse.onerror = (error) => {
      onError?.(error);
      sse.close();
    };

    return () => {
      sse.close();
    };
  }, [enabled]);

  return {};
};
