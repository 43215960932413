import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledProductsAnimationWrapper = styled(motion.div)`
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1400;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  gap: 20px;
`;

export const StyledProductsWrapper = styled(motion.div)`
  align-items: center;
  display: flex;
  max-width: 70%;
  flex-wrap: wrap;
  justify-content: center;

  .product-container {
    position: relative;
    display: inline-block;

    .product-img {
      width: 150px;
      height: auto;
    }
    .sparkling-background {
      position: absolute;
      z-index: -1;
      origin-x: 0.5;
      origin-y: 0.5;
      width: 200px;
      height: auto;
      left: -25px;
      top: -25px;
      transform: translateX(-50%);
    }
  }
`;
