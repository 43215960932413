import { isMobile, osName, browserName } from 'react-device-detect';
import { BootResponse, Profile } from '../../constants/apiResponses.types';
import useApi from '../../hooks/useApi';
import { loginPageService } from './login-page.service';
import { useCallback, useMemo } from 'react';
import useCustomEvents from '../../hooks/useCustomEvents';
import {
  EBundlesInternalViewModel,
  EEventsType,
  ELocalStorageKeys,
  EOTPTextModel,
  ESessionStorageKeys,
  EStorePhase,
} from '../../constants/enums';
import { useLocation } from 'react-router-dom';
import { webStorageUtil } from 'state/webStorage.state.service';
import { isDesktop } from 'react-device-detect';

export const useLoginPage = () => {
  const location = useLocation();

  const API = useApi({
    platform: isMobile ? osName : browserName,
  });

  const profiles = API.getProfiles.data;
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const { sendCustomEvent, getEventsSharedProps } = useCustomEvents();

  const redirectState = webStorageUtil.get(ESessionStorageKeys.REDIRECT_STATE);

  const playerAuthData = useMemo(
    () => publisherMetaData?.integration?.playersAuthentication,
    [publisherMetaData?.integration?.playersAuthentication]
  );

  const isWebview = useMemo(
    () => loginPageService.isWebviewBrowser(),
    [navigator.userAgent, location]
  );

  const trackLoginEvent = useCallback(async (eventName: string) => {
    if (eventName === EEventsType.LOGIN_CANCELED && redirectState) {
      sendCustomEvent(
        EEventsType.LOGIN_CANCELED,
        loginPageService.getLoginEventsData({
          eventSharedProps: getEventsSharedProps(),
        }),
        EStorePhase.PRE_LOGIN
      );
      webStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
    }
  }, []);

  const isProductMadness = useMemo(
    () =>
      [EBundlesInternalViewModel.JACKPOT_SLOTS, EBundlesInternalViewModel.BIG_FISH_CASINO].includes(
        publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel || ''
      ),
    [publisherMetaData?.storeTheme?.general?.bundlesInternalViewModel]
  );

  const showOtpButton = useMemo(
    () =>
      playerAuthData.otpOn &&
      (isDesktop
        ? playerAuthData?.otpTextModel && playerAuthData?.otpTextModel !== EOTPTextModel.MOBILE_ONLY
        : true),
    [playerAuthData, isWebview, isDesktop]
  );

  const fbAppId = useMemo(() => {
    if (!profiles) return publisherMetaData.integration.playersAuthentication.fbAppId;
    const defaultProfile = profiles.find((p: Profile) => p.name === 'Default');
    const currentProfile = profiles.find((p: Profile) => p.name === webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE)) || defaultProfile;
    return currentProfile?.fbAppId;
  }, [profiles, publisherMetaData?.integration.playersAuthentication.fbAppId]);

  return {
    API,
    fbAppId,
    publisherMetaData,
    isWebview,
    isProductMadness,
    showOtpButton,
    trackLoginEvent,
  };
};
