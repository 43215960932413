import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createManifest } from './utils';
import { webStorageUtil } from 'state/webStorage.state.service';
import { ETheme, themes } from './themes/theme';
import { BootResponse } from './constants/apiResponses.types';
import { ELocalStorageKeys, ESessionStorageKeys } from './constants/enums';
import useApi from './hooks/useApi';
import useAnalytics from './hooks/useAnalytics';
import useSetCssVars from './hooks/useSetCssVars';
import { LoginTemplate } from './pages/login/LoginTemplate';
import { Maintenance } from './pages/maintenance/maintenance';
import { Failed } from './pages/failed/failed';
import Support from './pages/Support/Support';
import { EventBatchProvider } from './components/EventsBatchProvider/EventBatchProvider';
import DevDialog from './components/DevDialog/DevDialog';
import { v4 as uuidv4 } from 'uuid';
import { AppchargeCheckoutInit } from 'appcharge-checkout-reactjs-sdk';
import { changeLanguage, initI18n } from './i18n';
import './styles.scss';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { ShopPage } from 'pages/Shop/ShopPage';
import { Loader } from './components/Loader/Loader';
import { useAppState } from './state/hooks/app.state.hook';
import { useLocalizationState } from './state/hooks/localization.state.hook';
import MaintenancePage from 'components/MaintenancePage/MaintenancePage';
import useUrlHash from 'hooks/useUrlHash';
import useUrlQuery from 'hooks/useUrlQuery';
import { DEFAULT_SYSTEM_LANGUAGE } from 'constants/defaults';
import { Footer } from 'components/footer/footer';
import { Background } from '@appcharge/shared-ui';
import { InfoModal } from 'components/InfoModal/InfoModal';

const theme = createTheme(themes[ETheme.LIGHT]);

type Environment = 'sandbox' | 'dev' | 'prod' | 'production' | undefined;
const DEFAULT_TTL = 3600000;
const SHARED_UI_VER = process.env.REACT_APP_SHARED_UI_VERSION;
const SDK_VER = process.env.REACT_APP_CHECKOUT_SDK_VERSION;
const ENV = process.env.REACT_APP_ENV as Environment;

console.log('SUIV: ' + SHARED_UI_VER);
console.log('ICSV: ' + SDK_VER);

function App() {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const [themeLoaded, setThemeLoaded] = useState(false);
  const { isI18nReady } = useLocalizationState();
  const [showDevDialog, setShowDevDialog] = useState(false);
  const { backgroundProps, displayLoader, setDisplayLoader, infoModalUrl } = useAppState();
  const facebookAccessToken = useUrlHash('#access_token');
  const isAppleRedirect = useUrlQuery('apple') === 'true';

  const isStandalone = window.location.search.includes('mode=standalone');
  const supportMultiLanguageEnabled = publisherMetaData?.featureFlags.store_multi_language_support;
  const currentLanguage = webStorageUtil.get(ELocalStorageKeys.CURRENT_LANGUAGE);

  if (API.getPublisherMeta.isIdle) {
    API.getPublisherMeta.refetch();
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source') === 'webview' ? 'webview' : '';
    webStorageUtil.set(ESessionStorageKeys.SOURCE, source);
  }, []);

  useEffect(() => {
    const isInLoginProcess = Boolean(facebookAccessToken || isAppleRedirect);
    const isLoadingApp = !isI18nReady || !publisherMetaData || isInLoginProcess;

    setDisplayLoader(isLoadingApp);
  }, [isI18nReady, publisherMetaData]);

  useEffect(() => {
    if (isStandalone) {
      webStorageUtil.set(ESessionStorageKeys.SOURCE, 'home screen bookmark');
    }
  }, [isStandalone]);

  useEffect(() => {
    const sessionData = webStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
    const params = new URLSearchParams(window.location.search);
    const sessionIdParam = params.get('sessionId');

    if (!sessionData || Date.now() > sessionData.ttl) {
      const sessionId = sessionIdParam ? sessionIdParam : uuidv4();
      const newTTl = Date.now() + DEFAULT_TTL;
      webStorageUtil.set(ESessionStorageKeys.SESSION_DATA, {
        id: sessionId,
        ttl: newTTl,
      });
    }
    if (sessionIdParam) {
      params.delete('sessionId');
      navigate(`${window.location.pathname}?${params.toString()}`, {
        replace: true,
      });
    }
  }, []);

  useEffect(() => {
    if (publisherMetaData && supportMultiLanguageEnabled) {
      const updateI18nConfig = async () => {
        await initI18n({
          getLanguagesList: API.getLanguagesList,
          publisherId: publisherMetaData.storeTheme.publisherId,
          language: publisherMetaData.language,
        });
      };
      updateI18nConfig();
    }
  }, [publisherMetaData?.storeTheme.publisherId, supportMultiLanguageEnabled]);

  useEffect(() => {
    if (
      publisherMetaData?.featureFlags &&
      !supportMultiLanguageEnabled &&
      currentLanguage !== DEFAULT_SYSTEM_LANGUAGE
    ) {
      changeLanguage('en');
    }
  }, [publisherMetaData?.featureFlags, currentLanguage, supportMultiLanguageEnabled]);

  useEffect(() => {
    API.getPublisherMeta.refetch();
  }, [currentLanguage]);

  // Set global CSS vars for theme
  useSetCssVars();

  const setFavicon = () => {
    if (publisherMetaData) {
      const fav = document.getElementById('favicon');
      fav!.setAttribute('href', publisherMetaData.storeTheme.general.favicon);
    }
  };

  const resetAppState = (utmSource?: string) => {
    API.getPostLoginData.remove();
    API.getOffers.remove();
    API.keepAlive.reset();

    webStorageUtil.clear();

    webStorageUtil.set(ESessionStorageKeys.SESSION_DATA, {
      id: uuidv4(),
      ttl: Date.now() + DEFAULT_TTL,
    });
    webStorageUtil.remove(ESessionStorageKeys.REDIRECT_STATE);
    setDisplayLoader(false);

    navigate(`/login${utmSource ? `?utm_source=${utmSource}` : ''}`, { replace: true });
  };

  const logout = async (utmSource?: string) => {
    API.logout.mutate(undefined, {
      onSettled: () => resetAppState(utmSource),
    });
  };

  if (API.getPublisherMeta.isSuccess && publisherMetaData) {
    if (!themeLoaded) {
      window.document.title = publisherMetaData.storeTheme.general.storeTabName;
      analytics.init();
      setFavicon();
      createManifest(publisherMetaData);
      setThemeLoaded(true);
    }
  }

  const featureFlagUnderMaintenance = publisherMetaData?.featureFlags?.store_under_maintenance;
  if (featureFlagUnderMaintenance) {
    return <MaintenancePage />;
  }

  return (
    <ErrorBoundary>
      <EventBatchProvider>
        <ThemeProvider theme={theme}>
          {displayLoader && <Loader />}
          <div
            style={{
              background: 'transparent',
              width: 30,
              height: 30,
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 100000,
              overflowX: 'hidden',
            }}
            onClick={(e) => {
              if (
                (window.location.hostname.includes('.appcharge.com') ||
                  window.location.hostname.includes('.appchargestore.com') ||
                  window.location.hostname.includes('localhost')) &&
                e.detail === 3
              ) {
                setShowDevDialog(true);
              }
            }}
          />
          {showDevDialog && <DevDialog onClose={() => setShowDevDialog(false)} />}
          {window.location.hostname.includes('.appcharge.com') &&
            (webStorageUtil.get(ELocalStorageKeys.OFFER_API_OVERRIDE) ||
              webStorageUtil.get(ELocalStorageKeys.BOOT_API_OVERRIDE) ||
              webStorageUtil.get(ELocalStorageKeys.POST_LOGIN_API_OVERRIDE)) && (
              <div
                style={{
                  position: 'fixed',
                  zIndex: 99,
                  background: 'red',
                  width: '100vw',
                  padding: '0.5em',
                  textAlign: 'center',
                  color: '#fff',
                }}
              >
                {'SOME ROUTES MOCKED'}
              </div>
            )}
          {themeLoaded && (
            <Background isStore {...backgroundProps}>
              <Routes>
                <Route path="/login/*" element={<LoginTemplate />} />
                <Route
                  path="/shop"
                  element={
                    webStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) ? (
                      <ShopPage logout={logout} />
                    ) : (
                      <Navigate to="/login" replace />
                    )
                  }
                />
                <Route path="/maintenance" element={<Maintenance />} />
                <Route path="/failed" element={<Failed />} />
                <Route path="/support/:supportType/:storePhase/:orderId?" element={<Support />} />
                <Route path="*" element={<Navigate to="/shop" replace />} />
              </Routes>
              {infoModalUrl && <InfoModal />}
              <Footer />
            </Background>
          )}
          {publisherMetaData && (
            <AppchargeCheckoutInit
              environment={ENV === 'production' ? 'prod' : ENV}
              checkoutToken={publisherMetaData.integration.checkoutPublicKey}
            />
          )}
        </ThemeProvider>
      </EventBatchProvider>
    </ErrorBoundary>
  );
}

export default App;
