import React, { useEffect, useRef, useState } from 'react';
import useApi from 'hooks/useApi';
import { BootResponse, OfferData } from 'constants/apiResponses.types';
import { HugeRollingOffer, OrderRollingOfferEvents, RollingOfferProps } from '@appcharge/shared-ui';
import { ECheckoutPageEvent, ERollingOffersInternalViewModel } from 'constants/enums';

const TIME_LEFT_INTERVAL = 1000;

const templateDesignDic: Record<ERollingOffersInternalViewModel, React.FC<RollingOfferProps>> = {
  [ERollingOffersInternalViewModel.HUGE]: HugeRollingOffer,
};

interface ISpecialOfferProps {
  selectOffer: () => void;
  data: OfferData;
}
type TimerState = { [key: string]: string };

export const RollingOffer: React.FC<ISpecialOfferProps> = ({ data, selectOffer }) => {
  const API = useApi({});
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const [timers, setTimers] = useState<TimerState>({});
  const [timeLeft, setTimeLeft] = useState<{ [key: string]: number | null }>({});

  const rollingOfferRef = useRef<OrderRollingOfferEvents>(null);
  const { rollingOffersInternalViewModel } = publisherMetaData.storeTheme.general;

  const RollingOfferDesign: React.FC<RollingOfferProps> =
    templateDesignDic[rollingOffersInternalViewModel || ERollingOffersInternalViewModel.HUGE];

  useEffect(() => {
    const eventHandler = (massageEvent: any) => {
      if (massageEvent.origin !== window.location.origin) return;
      const { event, params } = massageEvent.data;

      if (event === ECheckoutPageEvent.BACK_TO_STORE) {
        const offer = params?.offer;
        const isCurrentOffer = offer?.offerId === data.offerId;

        if (isCurrentOffer) {
          if (params.isOrderSucceed) {
            API.getOffers.refetch();
            rollingOfferRef?.current?.onOrderSuccess();
          } else {
            rollingOfferRef?.current?.onOrderFail();
          }
        }
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  // Format the timeLeft into HH:MM:SS format based on offerId
  useEffect(() => {
    const offerTimeLeft = timeLeft[data.offerId];
    if (!offerTimeLeft || offerTimeLeft < 0) {
      setTimers((prev) => ({ ...prev, [data.offerId]: '' }));
    } else {
      const totalSeconds = Math.floor(offerTimeLeft / 1000);
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = totalSeconds % 60;
      setTimers((prev) => ({
        ...prev,
        [data.offerId]: `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      }));
    }
  }, [timeLeft[data.offerId]]);

  // Effect to trigger the timer calculation when productsSequence length or schedule changes
  useEffect(() => {
    if (!data.productsSequence?.length) {
      setTimers((prev) => ({ ...prev, [data.offerId]: '' }));
      setTimeLeft((prev) => ({ ...prev, [data.offerId]: null }));
    } else if (data?.schedule && !data.schedule.permanent) {
      const endTime = data?.schedule?.timeFrames[0]?.endTime;
      if (endTime) {
        calculateTimeLeft(endTime, data.offerId);
      }
    }
  }, [data]);

  // Calculate time left until the endTime
  const calculateTimeLeft = (endTime: string | Date, offerId: string) => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const endTimeDate = new Date(endTime).getTime();

      const remainingTime = endTimeDate - currentTime;

      setTimeLeft((prev) => ({
        ...prev,
        [offerId]: remainingTime > 0 ? remainingTime : null,
      }));
      if (remainingTime <= 0) {
        clearInterval(interval);
        setTimers((prev) => ({ ...prev, [offerId]: '' }));
        API.getOffers.refetch();
      }
    }, TIME_LEFT_INTERVAL);

    return () => clearInterval(interval);
  };

  return (
    <RollingOfferDesign
      ref={rollingOfferRef}
      offerId={data.offerId}
      indexToCollect={data.indexToCollect}
      collectBehavior={data.offerUi.rollingOffer!.collectBehavior}
      backgroundColor={data.offerUi?.rollingOffer?.backgroundColor}
      backgroundImage={data.offerUi.backgroundImage}
      borderColor={data.offerUi.borderColor}
      borderWidth={data.offerUi.borderWidth}
      arrowColor={data.offerUi.rollingOffer!.arrowColor}
      template={rollingOffersInternalViewModel || ERollingOffersInternalViewModel.HUGE}
      subRollingOffersProducts={data.productsSequence}
      subRollingOfferUiProps={{
        ...data.offerUi.rollingOffer!.subRollingOffer,
        buttonColor: publisherMetaData.storeTheme.general.buttonColor,
        buttonTextColor: publisherMetaData.storeTheme.general.buttonTextColor,
      }}
      selectOffer={selectOffer}
      playerAvailability={data.offerPlayerRemainingAvailability}
      availability={data.playerAvailability}
      timer={timers[data.offerId] || ''}
    />
  );
};
