import { isMobile, isTablet } from 'react-device-detect';
import { BadgeData, Gradient } from '@appcharge/shared-ui';
import {
  ECheckoutPageEvent,
  EPlatform,
  ELocalStorageKeys,
  EOfferType,
  ESessionStorageKeys,
} from './constants/enums';
import _ from 'lodash';
import { BootResponse, OfferData, SequencedProduct } from './constants/apiResponses.types';
import { OfferDataForPublisherEvent } from './constants/apiRequest.types';
import { webStorageUtil } from 'state/webStorage.state.service';

export const createGradientOrSolid = (color?: Gradient | string) => {
  if (typeof color === 'string') {
    return color;
  }

  const { colorOne, colorTwo, gradientDirection } = color;

  if (colorOne && !colorTwo) {
    return colorOne;
  }
  return `linear-gradient(${gradientDirection || 'to right'}, ${colorOne}, ${colorTwo})`;
};

export const isOffersCooldown = () => {
  const lastOffersFetch = webStorageUtil.get(ELocalStorageKeys.OFFERS_COOLDOWN);

  if (new Date().getTime() < lastOffersFetch) return true;

  const cooldownTime = 60 * 1000;
  webStorageUtil.set(ELocalStorageKeys.OFFERS_COOLDOWN, new Date().getTime() + cooldownTime);
  return false;
};

export const collectImagesFromObject = (obj: object, imgSet: Set<string> = new Set()) => {
  const imageRegex = /\.(png|jpg|jpeg|webp)$/i;
  const imageUrlPath = 'appcharge.com/media/';
  const imageKeys = ['Image', 'product', 'background'];

  _.forEach(obj, (value, key) => {
    if (_.isObject(value)) {
      collectImagesFromObject(value, imgSet);
    } else if (
      imageKeys.some((ik) => key.includes(ik)) &&
      typeof value === 'string' &&
      (imageRegex.test((value as string).toLowerCase()) || (value as string).includes(imageUrlPath))
    ) {
      imgSet.add(value);
    }
  });

  if (imgSet.size === 0) {
    imgSet.add('noImage');
  }

  return imgSet;
};

export const getBadgeName = (position: string, badges?: BadgeData[]) => {
  const currentBadge = badges?.find((item) => item.position === position);
  return currentBadge?.name ?? '';
};

export enum EPublisher {
  APPLOVIN = 'applovin',
  BABA = 'baba',
  COMMUNIX = 'communix',
  HUUUGE = 'huuuge',
  INNER_USERS = 'appcharge',
  BAGELCODE = 'bagelcode',
  SPACEGO = 'spacego',
  PIPA = 'pipa',
}

const publishersIds: Record<EPublisher, string[]> = {
  [EPublisher.APPLOVIN]: [
    '653fcdf776d073ede1c8eef1',
    '6540b52ac1a6e85f78271f49',
    '657977737489eb830d0b483a',
    '64fda356c8327d50fe9a4b39',
  ],
  [EPublisher.BABA]: ['64be5980f517abb6ad681707', '63ec904c793c0ad830e70b3e'],
  [EPublisher.COMMUNIX]: ['64be592ff517abb6ad6814de', '6405c33ca94d31c05324665e'],
  [EPublisher.HUUUGE]: [
    '654ced5e72c66f2e63cba971',
    '64fdb84d3bc0c7c7f396c5ff',
    '64ad63bb94cd6d20b4a3005a',
    '647613d715ac6dd12fae858e',
    '64fdb7bf3bc0c7c7f396c5a6',
    '64d36c69641971fa95931413',
    '64fdb8be3bc0c7c7f396c668',
    '64fdb8983bc0c7c7f396c645',
    '641026dc506b12bf96a88368',
    '64fda38bc8327d50fe9a4b5f',
    '65ca1662028a629cc205a611', // HUUUGE INTERNAL (Appcharge)
    '66fa3616cb54b8d49c52d719',
    '66fa3660cb54b8d49c52db7b',
    '672bdeba7a99e6871479ea14',
    '672bdef37a99e6871479ec4f',
    '672bdfbc7a99e6871479f101',
    '672bdfe97a99e6871479f33c',
    '672be2147a99e6871479fb63',
    '672be2337a99e6871479fd9b',
    '66fa39452d31da0c00ac7606',
    '66fa3965e84cbe0056aaf9e1',
  ],
  [EPublisher.INNER_USERS]: [
    '652e47bbaf70d41b8b009a71', // Rony
    '66be029440fd9d64e8b421a9', // Barak
    '64d36d08b5ccf91f72f91fb0', // Narine
    '64ad4f25cc1a482bac467ae5', // Twili
    '64ad4f57cc1a482bac467b04', // Max
    '64ad4e68cc1a482bac467482', // Ani
    '63e9fd81dbfb53b47937171f', // Fanny
    '6555b69c02ee8eca00465694', // Nare
    '64d0a401b5ccf91f72efb492', // Olga
    '64ad554acc1a482bac4698e6', // Chen
    '666ebd5f6558b530f79555d6', // Yarden
  ],
  [EPublisher.BAGELCODE]: ['65a7981a2d887f00c2fda251'],
  [EPublisher.SPACEGO]: ['65f940bf6e82b3f857d40ba2'],
  [EPublisher.PIPA]: ['659bce96140252be98df7c4b'],
};

export const featureFlagsUtil = {
  isPublisher: (publisher: EPublisher) => {
    const publisherId = webStorageUtil.getAny(ELocalStorageKeys.PUBLISHER_META)?.storeTheme
      ?.publisherId;
    return publisherId ? publishersIds[publisher].includes(publisherId) : false;
  },
};

export const getPlatformData = () => {
  let platform;
  switch (true) {
    case isMobile:
      platform = EPlatform.MOBILE;
      break;
    case isTablet:
      platform = EPlatform.TABLET;
      break;
    default:
      platform = EPlatform.DESKTOP;
  }
  return platform.toLowerCase();
};

export interface CheckoutEventParams {
  supportUrl: string;
  returnToGameLinkAddress: string;
  isOrderSucceed: boolean;
}

export interface OrderPopupEvents {
  onOrderFail: () => void;
  onOrderSuccess: () => void;
}

export const sendMessageToIframeParent = (
  eventName: ECheckoutPageEvent,
  params: Partial<CheckoutEventParams>
) => {
  const eventData = {
    event: eventName,
    params,
  };
  window.parent.postMessage(eventData, '*');
};

export const getCurrentSubRollingOffer = (offerData: OfferData): SequencedProduct | undefined => {
  const productIndexToCollect = offerData.indexToCollect;

  return offerData?.productsSequence.find(({ index }) => index === productIndexToCollect);
};

export const getSelectedProduct = (
  offerData: OfferData,
  popupIndexToCollect: number
): SequencedProduct | undefined => {
  const productIndexToCollect = offerData.offerType === EOfferType.POP_UP ? popupIndexToCollect : 0;

  return offerData.offerType === EOfferType.ROLLING_OFFER
    ? getCurrentSubRollingOffer(offerData)
    : offerData?.productsSequence[productIndexToCollect];
};

export const extractOfferMainDetails = (offers: OfferData[]): OfferDataForPublisherEvent[] => {
  return offers.map((offer: OfferData) => ({
    offerId: offer.offerId,
    offerName: offer.offerName,
    publisherOfferId: offer.publisherOfferId || offer.offerId,
    offerType: offer.offerType,
    offerIndex: offer.indexToCollect,
    pricePoint:
      offer.productsSequence[offer.indexToCollect ? offer.indexToCollect - 1 : 0]?.prices
        ?.priceInUsdCents / 100,
    offerSubType: offer.subType,
    products: offer.productsSequence[
      offer.indexToCollect ? offer.indexToCollect - 1 : 0
    ].products.map((product) => ({
      publisherProductId: product.publisherProductId,
      amount: product.quantity,
    })),
  }));
};

export const createManifest = (publisherMetadata: BootResponse) => {
  const isMatchMasters = publisherMetadata.storeTheme.general.bundlesInternalViewModel === 'match';
  const appIcon = publisherMetadata.storeTheme.storeScreen.addToHomeScreen?.active
    ? publisherMetadata.storeTheme.storeScreen.addToHomeScreen?.iconImage
    : publisherMetadata.storeTheme.general.favicon;
  const storeName = publisherMetadata.storeTheme.storeScreen.addToHomeScreen?.active
    ? publisherMetadata.storeTheme.storeScreen.addToHomeScreen?.shortcutName
    : publisherMetadata.storeTheme?.general?.storeTabName || 'Gaming App';
  if (!appIcon) console.error('Add to home screen icon is missing in the publisher metadata');
  const iconAppSrc = isMatchMasters
    ? 'https://media.appcharge.com/media/match-masters-bookmark.png'
    : appIcon;

  const manifest = {
    name: storeName,
    short_name: storeName,
    icons: [
      {
        src: iconAppSrc,
        type: 'image/png',
        sizes: '192x192',
      },
      {
        src: iconAppSrc,
        type: 'image/png',
        sizes: '512x512',
      },
    ],
    display: 'browser',
    theme_color: '#ffffff',
    background_color: '#ffffff',
    start_url: `${window.location.origin}${window.location.pathname.replace('/login', '/shop')}?mode=standalone`,
    apple_mobile_web_app_capable: 'yes',
    apple_mobile_web_app_status_bar_style: 'black-translucent',
    apple_mobile_web_app_title: publisherMetadata.storeTheme?.general?.storeTabName || 'Gaming App',
    apple_touch_icon: publisherMetadata.storeTheme.general.logo,
  };

  const blob = new Blob([JSON.stringify(manifest)], {
    type: 'application/json',
  });
  const manifestURL = URL.createObjectURL(blob);

  // Inject the manifest link into the <head> dynamically
  const link = document.createElement('link');
  link.rel = 'manifest';
  link.href = manifestURL;
  document.head.appendChild(link);
};

export const getAppVersion = () => {
  if (!process.env.REACT_APP_STORE_VERSION || !process.env.REACT_APP_SHARED_UI_VERSION) {
    return 'dev';
  }
  const version =
    process.env.REACT_APP_STORE_VERSION.replace('HF', '') +
      process.env.REACT_APP_SHARED_UI_VERSION?.replace('HF', '') || '';
  return version ? `v${version}` : 'dev';
};

export const generateQueryParam = (params: Record<string, string | number>) => {
  const queriesKeys = Object.keys(params).filter((key: string) => !!params[key]);
  if (Object.keys(queriesKeys).length === 0) return '';
  const query =
    '?' + queriesKeys.map((key) => `${key}=${encodeURIComponent(params[key])}`).join('&');
  return query;
};

export const getUtmParams = (): Record<string, string> => {
  const storedUtmParams = webStorageUtil.get(ESessionStorageKeys.UTM_PARAMS);
  if (storedUtmParams) return storedUtmParams;

  const params = new URLSearchParams(window.location.search);
  const utmData: Record<string, string> = {};
  const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign'];

  utmKeys.forEach((key) => {
    const value = params.get(key);
    if (value) {
      utmData[key] = value;
    }
  });

  if (Object.keys(utmData).length > 0) {
    webStorageUtil.set(ESessionStorageKeys.UTM_PARAMS, utmData);

    return utmData;
  }

  return {};
};
