import { BootResponse, LoginResponse } from 'constants/apiResponses.types';
import {
  ESessionStorageKeys,
  EPlatformType,
  ELocalStorageKeys,
  EEventsType,
  EResultOptions,
  ELoginResultReason,
  EStorePhase,
  EQueryParams,
  ELoginEvents,
  ELoginMethods,
  ELoginMode,
} from 'constants/enums';
import useFacebook from 'hooks/useFacebook';
import { getPlatformData } from 'utils';
import { webStorageUtil } from 'state/webStorage.state.service';
import useGoogle from 'hooks/useGoogle';
import useUrlQuery from 'hooks/useUrlQuery';
import useCustomEvents from 'hooks/useCustomEvents';
import useLoginEvents from 'hooks/useLoginEvents';
import { useNavigate } from 'react-router-dom';
import { useObservable } from 'state/observable/observable.state.hook';
import { LoginData, LoginRequestData } from 'pages/login/types';
import useApi from 'hooks/useApi';
import { isMobile, osName, browserName, isIOS, isAndroid } from 'react-device-detect';
import { loginStateService } from 'state/login.state.service';
import { useTranslation } from 'react-i18next';
import { useAppState } from './app.state.hook';
import { loginPageService } from 'pages/login/login-page.service';
import { useMemo } from 'react';

export const useLoginState = () => {
  const API = useApi({
    platform: isMobile ? osName : browserName,
  });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const facebook = useFacebook(publisherMetaData.integration.playersAuthentication.fbAppId);
  const google = useGoogle();
  const localAddress = useUrlQuery(EQueryParams.LOCAL_ADDRESS);
  const customEvents = useCustomEvents();
  const { sendPublisherWebhookLoginEvent, calcLoginDuration, recordLoginStartTime } =
    useLoginEvents();
  const navigate = useNavigate();
  const isCampaignPath = location.pathname.includes('/campaign/');
  const { t } = useTranslation();
  const { setDisplayLoader, utms, isIntegrationProfilesEnabled } = useAppState();

  const utmSource = useMemo(() => utms?.utmSource, [utms?.utmSource]);

  const loginResponse = async (response: LoginData) => {
    setDisplayLoader(true);
    if (response.authMethod === 'facebook') {
      try {
        response.profileImageUrl = await facebook.getFacebookProfile(response.userToken!);
      } catch (error) {
        console.error('Fail to load profile picture', error);
        // Handle the error or take appropriate action here
      }
    }
    if (response.authMethod === 'google') {
      response.profileImageUrl = await google.getGoogleProfile(response.userToken!);
    }
    if (isIntegrationProfilesEnabled) {
      response.userToken = `${response.userToken}::${webStorageUtil.get(ELocalStorageKeys.INTEGRATION_PORFILE)}`;
    }

    const sessionData = webStorageUtil.get(ESessionStorageKeys.SESSION_DATA);
    const loginData: {
      data: LoginRequestData;
    } = {
      data: {
        publisherId: publisherMetaData.storeTheme.publisherId,
        localAddress: localAddress,
        sessionId: sessionData.id,
        ...response,
      },
    };
    if (publisherMetaData?.featureFlags.store_send_os_authlogin) {
      loginData.data.os = isIOS
        ? EPlatformType.IOS
        : isAndroid
          ? EPlatformType.ANDROID
          : EPlatformType.WEB;
    }
    if (response.authMethod === 'otp' && loginStateService.proofKey.get()) {
      loginStateService.setLoggingWithProofKey(true);
    }
    if (!isCampaignPath) {
      try {
        customEvents.sendCustomEvent(
          EEventsType.LOGIN_ATTEMPTED,
          loginPageService.getLoginEventsData({
            eventSharedProps: customEvents.getEventsSharedProps(),
            method: response.authMethod,
          }),
          EStorePhase.PRE_LOGIN
        );
        const data: { data: LoginResponse } = await API.login.mutateAsync(loginData.data);
        const loginDuration = calcLoginDuration();
        const loginDataToSend = {
          result: EResultOptions.SUCCESS,
          playerId: data.data.playerId,
        };
        if (data.data.errorCode === 100) {
          return failedLoginHandler(response.authMethod, data.data.errorMessage);
        }
        data.data.sessionToken &&
          webStorageUtil.set(ELocalStorageKeys.SESSION_TOKEN, data.data.sessionToken);
        webStorageUtil.set(ELocalStorageKeys.PLAYER_DATA, {
          playerId: data.data.playerId,
          playerName: data.data.playerName,
          playerCountry: data.data.playerCountry,
          profileImageUrl: data.data.profileImageUrl,
        });
        customEvents.setDistinctId();
        customEvents.sendCustomEvent(
          EEventsType.LOGIN_RESULT,
          {
            authMethod: response.authMethod,
            ...loginDataToSend,
            login_duration: loginDuration,
            platform: getPlatformData(),
          },
          EStorePhase.POST_LOGIN
        );
        sendPublisherWebhookLoginEvent({
          eventName: ELoginEvents.LOGIN_RESOLVED,
          method: response.authMethod,
          options: {
            ...loginDataToSend,
            duration: loginDuration,
          },
        });
        navigate(`/shop${utmSource ? `?utm_source=${utmSource}` : ''}&login_redirect=true`);
      } catch (error: any) {
        const defaultErrorMessage = t('login.errorMessages.loginFailed');
        const { enrichedMessage, code, message } = error.response?.data || {};
        const errorCode = error.response?.data?.code;
        const errorMessage = enrichedMessage
          ? undefined
          : code === 100
            ? message
            : errorCode >= 500 && errorCode <= 3000
              ? t('login.errorMessages.serviceIsDown')
              : defaultErrorMessage;
        failedLoginHandler(response.authMethod, errorMessage, enrichedMessage);
      }
    }
  };

  const failedLoginHandler = async (method: string, msg?: string, enrichedCustomMsg?: string) => {
    const loginFailureData = {
      result: EResultOptions.FAILED,
      reason: msg || enrichedCustomMsg || ELoginResultReason.UNKNOWN,
    };
    customEvents.sendCustomEvent(
      EEventsType.LOGIN_RESULT,
      {
        authMethod: method,
        ...loginFailureData,
        login_duration: calcLoginDuration(),
        platform: getPlatformData(),
      },
      EStorePhase.PRE_LOGIN
    );
    sendPublisherWebhookLoginEvent({
      eventName: ELoginEvents.LOGIN_RESOLVED,
      method: method as ELoginMethods,
      options: loginFailureData,
    });
    const queryParams = new URLSearchParams({
      error: 'auth',
      ...(msg && { msg }),
    });
    navigate(`/failed?${queryParams.toString()}`, {
      state: enrichedCustomMsg ? { enrichedCustomMsg } : undefined,
    });
  };

  const navigateToMode = (mode: ELoginMode) => {
    return mode !== 'providers' ? navigate(`./${mode}`) : navigate(-1);
  };

  const loginButtonClickWrapper = async (callback: Function, method: ELoginMethods) => {
    if (
      method === ELoginMethods.FACEBOOK ||
      method === ELoginMethods.GOOGLE ||
      method === ELoginMethods.APPLE
    ) {
      recordLoginStartTime();
      setDisplayLoader(true);
    }
    if (!loginPageService.isWebviewBrowser()) {
      customEvents.sendCustomEvent(
        EEventsType.LOGIN_CLICKED,
        loginPageService.getLoginEventsData({
          eventSharedProps: customEvents.getEventsSharedProps(),
          method,
        }),
        EStorePhase.PRE_LOGIN
      );
    }
    sendPublisherWebhookLoginEvent({ eventName: ELoginEvents.LOGIN_METHOD_SELECTED, method });
    webStorageUtil.set(ESessionStorageKeys.REDIRECT_STATE, `${method}redirect`);
    callback();
  };

  const handleBackBtnClicked = async () => {
    const mode = loginStateService.mode.get() as ELoginMode;
    navigateToMode(ELoginMode.PROVIDERS);
    customEvents.sendCustomEvent(
      EEventsType.LOGIN_CANCELED,
      loginPageService.getLoginEventsData({
        eventSharedProps: customEvents.getEventsSharedProps(),
      }),
      EStorePhase.PRE_LOGIN
    );
    sendPublisherWebhookLoginEvent({
      eventName: ELoginEvents.LOGIN_CANCELED,
      method: loginPageService.mapLoginModeToLoginMethod[mode],
    });
  };

  return {
    showCookiesOverlay: useObservable(loginStateService.showCookiesOverlay),
    proofKey: useObservable(loginStateService.proofKey),
    loggingWithProofKey: useObservable(loginStateService.loggingWithProofKey),
    otpToken: useObservable(loginStateService.otpToken),
    mode: useObservable(loginStateService.mode),
    hasConsent: useObservable(loginStateService.hasConsent),
    shouldShowTermsAlert: useObservable(loginStateService.shouldShowTermsAlert),
    isTermsAlertShaking: useObservable(loginStateService.isTermsAlertShaking),
    setShowCookiesOverlay: loginStateService.setShowCookiesOverlay,
    setProofKey: loginStateService.setProofKey,
    setLoggingWithProofKey: loginStateService.setLoggingWithProofKey,
    setOtpToken: loginStateService.setOtpToken,
    setMode: loginStateService.setMode,
    setHasConsent: loginStateService.setHasConsent,
    setShouldShowTermsAlert: loginStateService.setShouldShowTermsAlert,
    setIsTermsAlertShaking: loginStateService.setIsTermsAlertShaking,
    loginResponse,
    failedLoginHandler,
    navigateToMode,
    loginButtonClickWrapper,
    handleBackBtnClicked,
    clear: loginStateService.clear,
  };
};
