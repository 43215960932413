import { AppchargeCheckout } from 'appcharge-checkout-reactjs-sdk';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import useApi from 'hooks/useApi';
import { useNavigate } from 'react-router-dom';
import {
  ECheckoutPageEvent,
  EEventsType,
  ELocalStorageKeys,
  EOfferType,
  EStorePhase,
  EOrderStatus,
  EResultOptions,
} from 'constants/enums';
import './style.scss';
import useCustomEvents from 'hooks/useCustomEvents';
import { webStorageUtil } from 'state/webStorage.state.service';
import { useLocalizationState } from 'state/hooks/localization.state.hook';
import { countryToLanguageMap } from 'constants/mapCountryLanguage';
import {
  AppchargeLocale,
  EventParams,
} from 'appcharge-checkout-reactjs-sdk/lib/components/ui/AppchargeCheckout';
import { BootResponse, OfferData, OrderData } from 'constants/apiResponses.types';
import { Overlay } from '@appcharge/shared-ui';
import { useAppState } from 'state/hooks/app.state.hook';
import { SuccessOrder } from './Components/SuccessOrder';
import { Failed } from 'pages/failed/failed';
import { getPlatformData, getSelectedProduct } from 'utils';
import { usePopupState } from 'state/hooks/popup.state.hook';
import useFEMonitoring from 'hooks/useFEMonitoring';
import { AccountApprovalModal } from './Components/AccountApprovalModal';

interface Session {
  token?: string;
  url?: string;
}

interface CheckoutProduct {
  name: string;
  amount: string;
  sku: string;
}

const MAX_TIMEOUT_OFFERS_REFETCH = 1000 * 60; // 1 minute

export interface CheckoutProps {
  close: () => void;
  selectedOffer: OfferData | string;
  shouldValidateAccount: boolean;
  collectId?: string;
  processing: boolean;
  setProcessing: (value: boolean) => void;
  setLoadingFromCampaign: (value: boolean) => void;
  currencyCode: string;
}

export const Checkout: React.FC<CheckoutProps> = ({
  close,
  selectedOffer,
  shouldValidateAccount,
  collectId,
  processing,
  setProcessing,
  setLoadingFromCampaign,
  currencyCode,
}) => {
  const navigate = useNavigate();
  const { logToNewRelic } = useFEMonitoring();
  const [session, setSession] = useState<Session>({});
  const offersFetchInterval = useRef<any>();
  const [isSettled, setIsSettled] = useState<boolean>(!!collectId);
  const [orderId, setOrderId] = useState<string>(collectId || '');
  const [orderResolvedState, setOrderResolvedState] = useState<
    EOrderStatus.CHARGE_SUCCEED | EOrderStatus.CHARGE_FAILED | EOrderStatus.PAYMENT_FAILED
  >();
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showFailed, setShowFailed] = useState<boolean>(false);
  const [showSuccessOrder, setShowSuccessOrder] = useState<boolean>(false);
  const [isAccountValidated, setIsAccountValidated] = useState<boolean>(false);
  const API = useApi({ orderId });
  const publisherMetaData = API.getPublisherMeta.data as BootResponse;
  const customEvents = useCustomEvents();
  const { languagesList, currentLanguage } = useLocalizationState();
  const {
    displayLoader,
    setDisplayLoader,
    setSelectedOffer,
    setFailedHashValidation,
    isIntegrationProfilesEnabled,
  } = useAppState();
  const { setDisplayPostPurchase } = usePopupState();
  const [poolingOrderInterval, setPoolingOrderInterval] = useState<NodeJS.Timeout | null>(null);
  const [poolingCounter, setPoolingCounter] = useState<number>(0);

  const locale = useMemo(
    () =>
      `${currentLanguage};${
        countryToLanguageMap[
          languagesList?.find(({ language }) => language === currentLanguage)?.country || ''
        ] || ''
      }`,
    [currentLanguage]
  );

  const productsAnimationCollect = useMemo(
    () =>
      publisherMetaData?.featureFlags?.store_collect_animation_post_free_collect_promotions_popups,
    [publisherMetaData?.featureFlags?.store_collect_animation_post_free_collect_promotions_popups]
  );

  const failedHashValidationHandler = (errorData: any) => {
    setFailedHashValidation(true);
    document.getElementById('root')?.classList.remove('screen-lock');
    setProcessing(false);
    customEvents.sendCustomEvent(
      EEventsType.OUTDATED_OFFER_FAILURE,
      errorData,
      EStorePhase.POST_LOGIN
    );
  };

  useEffect(() => {
    if (collectId) return;
    setProcessing(true);
    let createSessionData;
    if (typeof selectedOffer !== 'string') {
      createSessionData = {
        offerId: (selectedOffer as OfferData).offerId,
        sequence: (selectedOffer as OfferData).indexToCollect || 0,
        offerJwt: (selectedOffer as OfferData).offerJwt,
      };
    } else {
      createSessionData = {
        offerId: selectedOffer,
        sequence: 0,
      };
    }
    API.createCheckoutSession.mutate(createSessionData, {
      onSuccess: (sessionData) => {
        setSession({
          url: sessionData.data.url,
          token: sessionData.data.checkoutSessionToken,
        });
        document.getElementById('root')?.classList.add('screen-lock');
      },
      onError: (error: any) => {
        if (error.response?.status === 410) {
          failedHashValidationHandler(error.response?.data?.body);
          return;
        }
        navigate('../failed?msg=creating order error');
      },
    });
  }, [selectedOffer]);

  useEffect(
    () => () => {
      clearPoolingInterval();
    },
    []
  );

  useLayoutEffect(() => {
    const checkoutIframe = document.querySelector(
      'iframe.iframe[title="checkout"]'
    ) as HTMLIFrameElement;

    if (checkoutIframe && isIntegrationProfilesEnabled) {
      checkoutIframe.style.height = 'calc(100dvh - 28px)';
      checkoutIframe.style.top = '28px';
    }
  }, [processing, isIntegrationProfilesEnabled]);

  const onResolve = (params: Partial<EventParams>) => {
    setOrderId(params.orderId || '');
  };

  const clearPoolingInterval = () => {
    if (poolingOrderInterval !== null) {
      clearInterval(poolingOrderInterval);
      setPoolingOrderInterval(null);
      setPoolingCounter(0);
    }
  };

  useEffect(() => {
    if (!orderId || poolingOrderInterval !== null) return;
    const poolingInterval = setInterval(async () => {
      try {
        if (
          ![
            EOrderStatus.CHARGE_SUCCEED,
            EOrderStatus.CHARGE_FAILED,
            EOrderStatus.PAYMENT_FAILED,
          ].includes(API.getOrder.data?.state)
        ) {
          setPoolingCounter((prev) => prev + 1);
          await API.getOrder.refetch();
        }
      } catch (err) {
        console.error(err);
        setShowFailed(true);
        setErrorCode('storeFailure');
      }
    }, 2000);

    setPoolingOrderInterval(poolingInterval);
  }, [orderId, API.getOrder.data]);

  useEffect(() => {
    const currentState = API.getOrder.data?.state;

    if (
      [
        EOrderStatus.CHARGE_SUCCEED,
        EOrderStatus.CHARGE_FAILED,
        EOrderStatus.PAYMENT_FAILED,
      ].includes(currentState)
    ) {
      setOrderResolvedState(currentState);
      clearPoolingInterval();
    } else if (poolingCounter >= 5) {
      logToNewRelic({
        message: `exceeds pooling timeout with order state: ${API.getOrder.data?.state} and orderId: ${orderId}`,
      });
      setProcessing(false);
      clearPoolingInterval();
      const eventData = {
        event: ECheckoutPageEvent.BACK_TO_STORE,
      };
      window.postMessage(eventData, '*');
    }
  }, [orderId, API.getOrder.data?.state, poolingCounter]);

  useEffect(() => {
    if (!orderResolvedState || !orderId) return;
    sendResolvedEvents();

    setProcessing(false);
    if (orderResolvedState === EOrderStatus.CHARGE_SUCCEED) {
      const offer: OrderData = API.getOrder.data.offer;
      if (
        typeof selectedOffer === 'string' ||
        offer.price !== 0 ||
        ((selectedOffer as OfferData).offerType === EOfferType.SPECIAL_OFFER &&
          !productsAnimationCollect)
      ) {
        setShowSuccessOrder(true);
      } else {
        API.getOffers.refetch();

        const eventData = {
          event: ECheckoutPageEvent.BACK_TO_STORE,
          params: {
            isOrderSucceed: true,
            offer: API.getOrder.data.offer,
          },
        };
        window.postMessage(eventData, '*');
      }
    } else {
      setTimeout(() => {
        setErrorMessage(API.getOrder.data.publisherErrorMessage || '');
        setErrorCode(orderResolvedState);
        setShowFailed(true);
      }, 1000);
      setSelectedOffer(null);
    }
  }, [orderResolvedState, orderId]);

  const sendResolvedEvents = () => {
    if (typeof selectedOffer !== 'string') {
      const currSelectedOffer = selectedOffer as OfferData;
      customEvents.sendCustomEvent(
        EEventsType.COLLECT_RESOLVED,
        {
          offer_id: currSelectedOffer?.offerId,
          result:
            orderResolvedState === EOrderStatus.CHARGE_SUCCEED
              ? EResultOptions.SUCCESS
              : EResultOptions.FAILED,
          offer_name: currSelectedOffer?.offerName,
          platform: getPlatformData(),
          type: currSelectedOffer?.offerType,
          sub_type: currSelectedOffer?.subType,
          reason:
            orderResolvedState !== EOrderStatus.CHARGE_SUCCEED ? API.getOrder.data.reason : '',
          index: currSelectedOffer?.indexToCollect,
        },
        EStorePhase.POST_LOGIN
      );
    }

    const { offerId, offerName, priceInUsd, salePercentage } = API.getOrder.data.offer;

    customEvents.sendCustomEvent(
      EEventsType.ORDER_RESOLVED,
      {
        status: orderResolvedState,
        order_id: orderId,
        offer_id: offerId,
        offer_name: offerName,
        offer_price_usd: priceInUsd,
        sale_percentage: salePercentage,
        platform: getPlatformData(),
      },
      EStorePhase.POST_LOGIN
    );
  };

  useEffect(() => {
    const eventHandler = (massageEvent: MessageEvent) => {
      if (
        massageEvent.origin !== window.location.origin &&
        !(massageEvent.origin as string).toLowerCase().includes('checkout-v2')
      )
        return;
      const { params, event } = massageEvent.data;
      switch (event) {
        case ECheckoutPageEvent.CHECKOUT_OPENED:
          if (new URLSearchParams(window.location.search).get('from') === 'campaign') {
            // TODO: remove this after implementing checkout deeplink event in IC
            customEvents.sendCustomEvent(EEventsType.CHECKOUT_OPENED, {
              offerId: selectedOffer,
              source: 'deeplink',
              source_type: 'dynamo',
            });
          }
          if (webStorageUtil.get(ELocalStorageKeys.IS_FREE_ORDER_SELECTED)) {
            API.getOffers.refetch();
            offersFetchInterval.current = setInterval(() => {
              API.getOffers.refetch();
            }, 1000);
          }
          webStorageUtil.remove(ELocalStorageKeys.IS_FREE_ORDER_SELECTED);
          break;
        case ECheckoutPageEvent.BACK_TO_STORE:
          if (
            typeof selectedOffer === 'string' ||
            (selectedOffer as OfferData)?.offerType !== EOfferType.ROLLING_OFFER
          ) {
            setDisplayPostPurchase(true);
            API.getOffers.refetch();
          }

          customEvents.sendCustomEvent(
            EEventsType.COMPLETE_SCREEN_BACK_TO_SHOP,
            {},
            EStorePhase.POST_LOGIN
          );

          offersFetchInterval.current && clearInterval(offersFetchInterval.current);
          document.getElementById('root')?.classList.remove('screen-lock');

          close();

          webStorageUtil.remove(ELocalStorageKeys.CURRENT_AVAILABILITY);
          break;
        case ECheckoutPageEvent.BACK_TO_GAME:
          customEvents.sendCustomEvent(
            EEventsType.COMPLETE_SCREEN_BACK_TO_GAME,
            {},
            EStorePhase.POST_LOGIN
          );
          window.location.assign(params.returnToGameLinkAddress);
          close();
          offersFetchInterval.current && clearInterval(offersFetchInterval.current);
          document.getElementById('root')?.classList.remove('screen-lock');
          break;
        case ECheckoutPageEvent.ORDER_COMPLETED_SUCCESS:
          offersFetchInterval.current &&
            setTimeout(() => {
              clearInterval(offersFetchInterval.current), MAX_TIMEOUT_OFFERS_REFETCH;
            });
          break;
        case ECheckoutPageEvent.ORDER_COMPLETED_FAILED:
          offersFetchInterval.current &&
            setTimeout(() => {
              clearInterval(offersFetchInterval.current), MAX_TIMEOUT_OFFERS_REFETCH;
            });
          break;
      }
    };

    window.addEventListener('message', eventHandler);

    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  useEffect(() => {
    setDisplayLoader(!showSuccessOrder && !showFailed);

    if (showSuccessOrder || showFailed) {
      clearPoolingInterval();
    }
  }, [isSettled, showFailed, showSuccessOrder]);

  const validateOfferData = (checkoutProducts: CheckoutProduct[]) => {
    if ((selectedOffer as OfferData)?.productsSequence) {
      checkoutProducts.forEach((checkoutProduct: CheckoutProduct) => {
        const selectedOfferProducts = getSelectedProduct(
          selectedOffer as OfferData,
          (selectedOffer as OfferData).indexToCollect || 0
        )?.products;

        const storeProduct = selectedOfferProducts?.find(
          (storeProduct) => storeProduct.publisherProductId === checkoutProduct.sku
        );
        if (!storeProduct || storeProduct?.quantity != checkoutProduct?.amount) {
          console.error('mismatch between checkout and store products quantities');
        }
      });
    }
  };

  const handleAccountApproval = () => {
    setIsAccountValidated(true);

    customEvents.sendCustomEvent(EEventsType.DEEPLINK_ACCOUNT_APPROVAL_APPROVE, {
      approved_account: true,
      offerId: selectedOffer,
      source: 'deeplink',
      source_type: 'dynamo',
    });
  };

  const handleAccountDisapproval = () => {
    customEvents.sendCustomEvent(EEventsType.DEEPLINK_ACCOUNT_APPROVAL_APPROVE, {
      approved_account: false,
      offerId: selectedOffer,
      source: 'deeplink',
      source_type: 'dynamo',
    });

    navigate('../failed?error=auth&msg=offer is not available');
  };

  return (
    <>
      {session.url && session.token && (
        <div
          className={isSettled ? 'hide-iframe' : ''}
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            height: '100lvh',
            width: '100vw',
            zIndex: '1500',
          }}
        >
          <AppchargeCheckout
            checkoutToken={publisherMetaData.integration.checkoutPublicKey}
            checkoutUrl={session.url}
            sessionToken={session.token}
            playerId={webStorageUtil.get(ELocalStorageKeys.PLAYER_DATA).playerId}
            referrerUrl={''}
            onClose={() => {
              close();
              document.getElementById('root')?.classList.remove('screen-lock');
            }}
            onOrderCreated={(event: Partial<EventParams>) => {
              validateOfferData(event.products as CheckoutProduct[]);
            }}
            onInitialLoad={() => {
              setProcessing(false);
              setLoadingFromCampaign(false);
              document.getElementById('root')?.classList.add('screen-lock');
            }}
            onOrderCompletedSuccessfully={onResolve}
            onOrderCompletedFailed={onResolve}
            onPaymentIntentSuccess={() => setIsSettled(true)}
            locale={locale as AppchargeLocale}
          />
          <AccountApprovalModal
            isOpen={shouldValidateAccount && !isAccountValidated}
            onApproval={handleAccountApproval}
            onDisapproval={handleAccountDisapproval}
          />
        </div>
      )}
      {isSettled && (
        <Overlay overlayPercentage={90} visible={!displayLoader}>
          {showSuccessOrder && <SuccessOrder currencyCode={currencyCode} orderId={orderId} />}
          {showFailed && (
            <Failed
              errorCodeProp={errorCode || EOrderStatus.CHARGE_FAILED}
              orderIdProp={orderId}
              isIframeProp={true}
              currencyCodeProp={currencyCode}
              messageProp={errorMessage}
            />
          )}
        </Overlay>
      )}
    </>
  );
};
