import './style.scss';
import { LoginResponse } from 'constants/apiResponses.types';
import { useNavigate } from 'react-router';
import React, { useEffect, useMemo } from 'react';
import { AcCircularLoader, Overlay, useIsDesktop, useIsLandscape } from '@appcharge/shared-ui';
import CookieConsent from 'components/cookie-consent/cookie-consent';
import useCustomEvents from 'hooks/useCustomEvents';
import { webStorageUtil } from 'state/webStorage.state.service';
import {
  EEventsType,
  ELocalStorageKeys,
  ELoginEvents,
  ELoginMethods,
  ELoginMode,
  ESessionStorageKeys,
  EStorePhase,
} from 'constants/enums';
import { Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { loginPageService } from './login-page.service';
import { Login } from './Components/Login';
import useLoginEvents from 'hooks/useLoginEvents';
import { useLoginState } from 'state/hooks/login.state.hook';
import { useAppState } from 'state/hooks/app.state.hook';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useLoginPage } from './useLoginPage.hook';
import { ProductMadnessLogin } from './Custom/ProductMadness/ProductMadnessLogin';
import useAnalytics from 'hooks/useAnalytics';
import { IntegrationProfilePicker } from './Components/IntegrationProfilePicker';
import { LOGIN_TEMPLATE_CLASS, STORE_BACKGROUND_CLASS } from 'constants/defaults';
import { StyledConsentOverlay } from './style';

const ENV = process.env.REACT_APP_ENV;
const DYNAMO_PLAYER_QUERY_PARAM = 'd10x_cuid';

export const LoginTemplate: React.FC = () => {
  const { API, publisherMetaData, isProductMadness } = useLoginPage();

  const { init } = useAnalytics();
  const customEvents = useCustomEvents();
  const { sendPublisherWebhookLoginEvent } = useLoginEvents();

  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useIsDesktop();
  const isLandscape = useIsLandscape();
  const { t } = useTranslation();
  const isCampaignPath = location.pathname.includes('/campaign/');
  const { isIntegrationProfilesEnabled, setIsIntegrationProfilesEnabled, utms } = useAppState();
  const urlParams = new URLSearchParams(window.location.search);

  const { setDisplayLoader } = useAppState();

  const {
    proofKey,
    loggingWithProofKey,
    showCookiesOverlay,
    hasConsent,
    shouldShowTermsAlert,
    setShouldShowTermsAlert,
    setIsTermsAlertShaking,
    setShowCookiesOverlay,
    setOtpToken,
    navigateToMode,
    setProofKey,
    clear,
  } = useLoginState();

  useEffect(() => {
    setShowCookiesOverlay(
      // ENV !== 'dev' && webStorageUtil.get(ELocalStorageKeys.ANALYTICS) === null
      webStorageUtil.get(ELocalStorageKeys.ANALYTICS) === null
    );
  }, [ENV]);

  const utmSource = useMemo(() => utms?.utmSource, [utms?.utmSource]);

  useEffect(() => {
    const onLoginLand = async () => {
      if (webStorageUtil.get(ELocalStorageKeys.SESSION_TOKEN) && !isCampaignPath) {
        navigate(`/shop${utmSource ? `?utm_source=${utmSource}` : ''}`);
      } else {
        sendPublisherWebhookLoginEvent({ eventName: ELoginEvents.LOGIN_LAND });
        if (!loginPageService.isWebviewBrowser()) {
          customEvents.sendCustomEvent(
            EEventsType.LOGIN_LAND,
            loginPageService.getLoginEventsData({
              eventSharedProps: customEvents.getEventsSharedProps(),
            }),
            EStorePhase.PRE_LOGIN
          );
        }
        if (webStorageUtil.get(ELocalStorageKeys.PUBLISHER_META) === null) {
          await API.getPublisherMeta.refetch();
        }
      }
    };
    onLoginLand();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Boolean(proofKey) || isCampaignPath) {
      setDisplayLoader(true);
    }
  }, [proofKey, isCampaignPath]);

  useEffect(() => {
    if (isCampaignPath) {
      const path = location.pathname;
      const token = path?.split('/')?.pop() || '';
      const queryParams = new URLSearchParams(location.search);
      const playerId = queryParams.get(DYNAMO_PLAYER_QUERY_PARAM);
      handleLoginFromCampaign({
        publisherId: publisherMetaData.storeTheme.publisherId,
        playerId: playerId || '',
        token,
      });
    }
  }, [isCampaignPath]);

  useEffect(() => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isStandalone) webStorageUtil.set(ESessionStorageKeys.SOURCE, 'home screen bookmark');
  }, [location]);

  useEffect(() => {
    if (publisherMetaData.featureFlags.store_integration_profiles && ENV !== 'production') {
      setIsIntegrationProfilesEnabled(true);
    }
    init();
  }, [showCookiesOverlay, publisherMetaData]);

  // prevent back-forward cache
  useEffect(() => {
    window.onpageshow = function (event) {
      if (event.persisted) {
        window.location.reload();
      }
    };

    return () => {
      window.onpageshow = null;
    };
  }, []);

  // Add boot vars to CSS VARS
  useEffect(() => {
    const generalTheme = publisherMetaData.storeTheme.general;
    const buttonTextColor = generalTheme.buttonTextColor;
    const root = document.documentElement;
    root.style.setProperty('--button-text-color', buttonTextColor);
  }, [publisherMetaData.storeTheme.general, publisherMetaData.storeTheme.storeScreen]);

  useEffect(() => {
    const checkURLforProofKey = () => {
      const proofKeyParam = urlParams.get('proofKey');
      const tokenKeyParam = urlParams.get('token');

      if (proofKeyParam && publisherMetaData) {
        setProofKey(proofKeyParam);
        navigateToMode(ELoginMode.OTP);
      }
      if (tokenKeyParam && publisherMetaData) {
        setOtpToken(tokenKeyParam);
      }
    };

    checkURLforProofKey();
  }, [publisherMetaData]);

  useEffect(() => {
    return function cleanup() {
      clear();
    };
  }, []);

  const handleLoginFromCampaign = async (response: any) => {
    API.login.mutate(
      {
        publisherId: response.publisherId,
        token: response.userToken,
        userToken: response.playerId,
        authMethod: ELoginMethods.TOKEN,
      },
      {
        onSuccess: async (data: { data: LoginResponse }) => {
          webStorageUtil.set(ELocalStorageKeys.SESSION_TOKEN, data.data.sessionToken);
          webStorageUtil.set(ELocalStorageKeys.PLAYER_DATA, {
            playerId: data.data.playerId,
            playerName: data.data.playerName,
            playerCountry: data.data.playerCountry,
            profileImageUrl: data.data.profileImageUrl,
          });
          webStorageUtil.set(ELocalStorageKeys.CAMPAIGN_OFFER_ID, data.data.offerId);
          const queryParams = new URLSearchParams(window.location.search);
          const d10xLinkId = queryParams.get('d10x_link_id');
          const d10xOrg = queryParams.get('d10x_org');
          webStorageUtil.set(ESessionStorageKeys.D10X_LINK_ID, d10xLinkId || '');
          webStorageUtil.set(ESessionStorageKeys.D10X_ORG, d10xOrg || '');

          navigate(
            `/shop${isCampaignPath ? `?from=campaign` : ''}&login_redirect=true&token=${response.token}&d10x_link_id=${d10xLinkId}&d10x_org=${d10xOrg}`
          );
        },
      }
    );
  };

  const googleAppId = useMemo(
    () => publisherMetaData?.integration?.playersAuthentication?.googleAppId || '',
    [publisherMetaData?.integration?.playersAuthentication?.googleAppId]
  );

  const consentRequired = useMemo(
    () => publisherMetaData?.storeTheme?.login?.consentSection?.consentRequired,
    [publisherMetaData?.storeTheme?.login?.consentSection?.consentRequired]
  );

  const showConsentOverlay = useMemo(
    () => consentRequired && !hasConsent && !showCookiesOverlay,
    [consentRequired, hasConsent, showCookiesOverlay]
  );

  const loginTemplateHeight = document.querySelector(`.${STORE_BACKGROUND_CLASS}`)?.scrollHeight;

  const handleClickOnLoginButton = () => {
    if (!hasConsent) {
      document
        .querySelector(`.${STORE_BACKGROUND_CLASS}`)
        ?.scrollIntoView({ block: 'start', behavior: 'smooth' });

      if (shouldShowTermsAlert) {
        setIsTermsAlertShaking(true);
        setTimeout(() => setIsTermsAlertShaking(false), 500);

        return;
      }
      setShouldShowTermsAlert(true);
    }
  };

  if (loggingWithProofKey) {
    return (
      <Overlay overlayPercentage={80} zIndex={10000}>
        <AcCircularLoader text={t('processing')} />
      </Overlay>
    );
  }

  return (
    <div className={LOGIN_TEMPLATE_CLASS}>
      {!isCampaignPath ? (
        <Stack
          display={isProductMadness && (isDesktop || isLandscape) ? 'block' : 'flex'}
          justifyContent={isMobile ? 'flex-start' : 'center'}
          alignItems="center"
          flex={1}
        >
          <GoogleOAuthProvider clientId={googleAppId}>
            {isProductMadness ? <ProductMadnessLogin /> : <Login />}
          </GoogleOAuthProvider>
        </Stack>
      ) : (
        <Stack width="100%" justifyContent="center" alignItems="center" flex={1}></Stack>
      )}
      {showConsentOverlay && (
        <StyledConsentOverlay
          className={'consent-overlay'}
          onClick={handleClickOnLoginButton}
          hasConsent={hasConsent}
          height={loginTemplateHeight}
        />
      )}
      {showCookiesOverlay && <CookieConsent />}
      {isIntegrationProfilesEnabled && <IntegrationProfilePicker />}
    </div>
  );
};
